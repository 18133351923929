import {type Editor} from '@englex/slate';

import ToolbarButton, {type ToolbarButtonOptions} from '../../ToolbarButton';
import {ButtonType} from '../../interface';

export default class Char extends ToolbarButton {
  public icon: string;
  public title = undefined;
  public type = ButtonType.CHAR;

  public toggleChange = (ch: Editor) => ch.insertText(this.icon);

  constructor({icon, toolbar}: ToolbarButtonOptions & {icon: string}) {
    super({toolbar});
    this.icon = icon;
  }

  public renderIcon = () => {
    return this.icon;
  };
}
