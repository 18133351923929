import {type DeserializeFn} from '@englex/slate-html-serializer';

import ToggleMark from './ToggleMark';
import {SlateMark, SlateObject} from '../../../interface';
import {buttonTitle} from './i18n';
import {ButtonType} from '../interface';
import RenderItalic from '../../../plugins/renderers/Italic';

class Italic extends ToggleMark {
  public type = ButtonType.ITALIC;
  public declare label?: undefined;
  public mark = SlateMark.ITALIC;
  public icon = 'italic';
  public title = buttonTitle.Italic;
  public shortcut = 'mod+i';

  public plugins = [new RenderItalic()];

  public fromHtml: DeserializeFn = (el: HTMLElement, next) => {
    let fontStyle: string | null = el.style && el.style.fontStyle;

    if (!fontStyle && el.tagName.toLowerCase() === 'i') {
      // macOS or msWord
      fontStyle = 'italic';
    }

    if (fontStyle && fontStyle === 'italic') {
      return {
        object: SlateObject.MARK,
        type: this.mark,
        nodes: next(el.childNodes)
      };
    }
    return;
  };
}

export default Italic;
