import {type Editor, type Mark} from '@englex/slate';
import {type Editor as ReactEditor} from '@englex/slate-react';
import {type OrderedSet} from 'immutable';

import {getVoidInlineSelectedOnlyMarks} from '../../interface';

/**
 * Custom active marks query.
 * Should be used instead of editor.value.activeMarks
 * @param editor
 */
export const getActiveMarks = (editor: Editor | ReactEditor): OrderedSet<Mark> => {
  if (!editor.hasQuery(getVoidInlineSelectedOnlyMarks)) {
    return editor.value.activeMarks;
  }
  const activeMarks = editor.query(getVoidInlineSelectedOnlyMarks) as OrderedSet<Mark> | undefined;
  return activeMarks || editor.value.activeMarks;
};
