import {type Editor} from '@englex/slate';

import {isMac} from 'helpers/shortcut';

import ToolbarButton from '../../ToolbarButton';
import {buttonTitle} from '../i18n';
import {ButtonType} from '../../interface';
import './Redo.scss';

class Redo extends ToolbarButton {
  public type = ButtonType.REDO;
  public icon = 'undo';
  public title = buttonTitle.Redo;
  public shortcut = isMac ? 'mod+y' : ['mod+shift+z', 'mod+y'];

  protected toggleChange = (change: Editor) => change.redo();

  public isDisabled(editor: Editor) {
    const redos = editor.value.data.get('redos');
    return editor.readOnly || !editor.value.selection?.isFocused || !redos || !redos.size;
  }
}

export default Redo;
