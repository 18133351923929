import {type Editor as ReactEditor} from '@englex/slate-react';
import {type Editor, type Next} from '@englex/slate';
import type React from 'react';

import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';
import {isInlineOfType} from '../../../../utils';
import {SlateInline} from '../../../../interface';
import ToolbarButton from '../../ToolbarButton';
import {replaceMarks, valueRanges} from './queries';

class FormatPainter extends ToolbarButton {
  public type = ButtonType.FORMAT_PAINTER;
  public title = buttonTitle.FormatPainter;
  public icon = 'virc-format-painter';
  public shortcut = 'mod+shift+c';

  protected toggleChange = (change: Editor) => {
    const {value} = change;
    if (change.value.data.get('formatBuffer')) {
      change.setData(change.value.data.delete('formatBuffer'));
      return;
    }

    const ranges = valueRanges(value);

    if (!ranges) return;

    const [range, collapsedRange] = ranges;

    let marks = value.document.getMarksAtRange(collapsedRange.moveForward(1));
    const inlines = value.inlines;
    if (!marks.size && inlines.size === 1) {
      if (!value.document.getTextsAtRange(range).first().text.length) {
        marks = inlines.first().getMarks();
      }
    }
    change.setData(change.value.data.set('formatBuffer', marks));
  };

  public onBlur = (event: React.FocusEvent, change: ReactEditor & Editor, next: Next) => {
    change.setData(change.value.data.delete('formatBuffer'));
    return next();
  };

  public isActive = (editor: Editor) => !!editor.value.data.get('formatBuffer');

  public isDisabled = (editor: Editor): boolean => {
    if (super.isDisabled(editor)) {
      return true;
    }
    const {
      value,
      value: {document}
    } = editor;
    const ranges = valueRanges(value);

    if (!ranges) return false;

    const [range, collapsedRange] = ranges;

    const textNode = document.getTextsAtRange(range).first();
    if (!textNode) {
      return true;
    }
    if (textNode.text.substring(range.start.offset, range.start.offset + 1)) {
      return false;
    }
    const inlines = value.inlines;
    const globalOffset = document.getOffsetAtRange(collapsedRange);
    if (inlines.size) {
      const inline = inlines.first();
      if (
        isInlineOfType(inline, SlateInline.ICON) &&
        globalOffset === document.getOffset(inline.key)
      ) {
        return false;
      }
    }
    if (range.isCollapsed) {
      const newRange = range.moveEndToEndOfNode(document);
      const inlines = document.getLeafInlinesAtRange(newRange);
      if (inlines.size) {
        const inline = inlines.first();
        if (
          isInlineOfType(inline, SlateInline.ICON) &&
          globalOffset === document.getOffset(inline.key)
        ) {
          return false;
        }
      }
    }
    return true;
  };

  public onMouseUp = (e: React.MouseEvent, editor: ReactEditor & Editor, next: Next) => {
    const buffer = editor.value.data.get('formatBuffer');
    if (buffer) {
      requestAnimationFrame(() =>
        editor.command(ch => {
          if (buffer) {
            replaceMarks(ch, buffer);
          }
        })
      );
    }
    return next();
  };
}

export default FormatPainter;
