import React from 'react';
import classNames from 'classnames';
import {type Plugin} from '@englex/slate-react';
import {type Editor} from '@englex/slate';

import ToolbarButton from '../ToolbarButton';
import {type RenderToolbarButtonProps, type ToolbarPlugin} from '../interface';
import ControlledTooltip from '../components/ControlledTooltip';
import SlateToolbar from '../../Toolbar/SlateToolbar';

import './NestedToolbar.scss';

abstract class NestedToolbar extends ToolbarButton implements ToolbarPlugin {
  public declare plugins: Plugin[];
  public maxItemsInRow?: number;
  public maxItemsInCol?: number;
  public vertical?: boolean;
  public tooltip: ControlledTooltip | null;
  protected toggleChange?: (change: Editor) => void;

  public renderToolbarButton(props: RenderToolbarButtonProps) {
    if (this.isDisabled(props.editor)) {
      return super.renderToolbarButton(props);
    }
    const className = classNames({
      [`max-in-col-${this.maxItemsInCol}`]: !!this.maxItemsInCol,
      [`max-in-row-${this.maxItemsInRow}`]: !!this.maxItemsInRow,
      vertical: this.vertical
    });
    return (
      <ControlledTooltip
        getTooltipContainer={this.getTooltipContainer(props.editor)}
        key={props.key}
        ref={(t: ControlledTooltip | null) => (this.tooltip = t)}
        prefixCls="slate-nested-toolbar"
        overlay={this.renderOverlay(props)}
        overlayClassName={className}
      >
        {super.renderToolbarButton(props)}
      </ControlledTooltip>
    );
  }

  public toggle = () => {
    if (this.tooltip) {
      this.tooltip.toggle();
    }
  };

  public show = () => {
    if (this.tooltip) {
      this.tooltip.show();
    }
  };

  public hide = () => {
    if (this.tooltip) {
      this.tooltip.hide();
    }
  };

  protected handleClick = () => {
    this.toggle();
  };

  protected renderOverlay = (props: RenderToolbarButtonProps) => {
    const classes = classNames('slate-editor-toolbar nested', [this.type], {
      vertical: !!this.vertical
    });
    return (
      <SlateToolbar className={classes} editor={props.editor} plugins={this.plugins} show={true} />
    );
  };
}

export default NestedToolbar;
