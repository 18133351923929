import {type Plugin} from '@englex/slate-react';
import {
  type Editor,
  type Document,
  type InvalidReason,
  type Query,
  type Rules,
  type SlateError,
  type Next
} from '@englex/slate';
import {type IntlShape} from 'react-intl';
import {type SerializeRules} from '@englex/slate-html-serializer';
import {type CSSProperties} from 'react';
import type React from 'react';

import {type SlateBlock, type SlateInline, type SlateMark, type SlateObject} from '../../interface';

export interface ButtonTitle {
  readonly id: string;
}

export interface QuestionProps {
  readonly type: ButtonType;
  readonly title: ButtonTitle;
  readonly label: ButtonTitle;
}

export enum ButtonType {
  BOLD = 'Bold',
  ITALIC = 'Italic',
  UNDERLINE = 'Underline',
  STRIKE_THROUGH = 'StrikeThrough',

  CLEAR_FORMATTING = 'ClearFormatting',
  FORMAT_PAINTER = 'FormatPainter',

  TEXT_ALIGNMENT = 'TextAlignment',
  TEXT_ALIGN_LEFT = 'TextAlignLeft',
  TEXT_ALIGN_CENTER = 'TextAlignCenter',
  TEXT_ALIGN_RIGHT = 'TextAlignRight',
  TEXT_ALIGN_JUSTIFY = 'TextAlignJustify',

  FONT_SIZE = 'FontSize',
  FONT_SIZE_SMALLEST = 'FontSizeSmallest',
  FONT_SIZE_SMALLER = 'FontSizeSmaller',
  FONT_SIZE_SMALL = 'FontSizeSmall',
  FONT_SIZE_DEFAULT = 'FontSizeDefault',
  FONT_SIZE_LARGE = 'FontSizeLarge',
  FONT_SIZE_LARGEST = 'FontSizeLargest',

  COLOR = 'Color',
  COLOR_YELLOW = 'ColorYellow',
  COLOR_ORANGE = 'ColorOrange',
  COLOR_RED = 'ColorRed',
  COLOR_BROWN = 'ColorBrown',
  COLOR_BLUE = 'ColorBlue',
  COLOR_GREEN = 'ColorGreen',
  COLOR_PURPLE = 'ColorPurple',

  LINK = 'Link',

  HIGHLIGHT = 'Highlight',
  HIGHLIGHT_ORANGE = 'HighlightOrange',
  HIGHLIGHT_YELLOW = 'HighlightYellow',
  HIGHLIGHT_RED = 'HighlightRed',
  HIGHLIGHT_BLUE = 'HighlightBlue',
  HIGHLIGHT_GREEN = 'HighlightGreen',

  IMAGE = 'Image',
  IMAGE_TEXT_WRAP_LEFT = 'ImageTextWrapLeft',
  IMAGE_TEXT_WRAP_CENTER = 'ImageTextWrapCenter',
  IMAGE_TEXT_WRAP_RIGHT = 'ImageTextWrapRight',
  IMAGE_RESIZE = 'ImageResize',

  ORDERED_LIST = 'OrderedList',
  ORDERED_ALPHA = 'OrderedListAlpha',
  ORDERED_DECIMAL = 'OrderedListDecimal',
  UNORDERED_LIST = 'UnorderedList',

  GAP_FILL_INPUT = 'GapFillInput',
  GAP_FILL_DROP_DOWN = 'GapFillDropDown',
  GAP_FILL_DND = 'GapFillDND',
  GAP_FILL_DND_INPUT = 'GapFillDNDInput',

  REDO = 'Redo',
  UNDO = 'Undo',

  READONLY = 'Readonly',

  INDENT = 'INDENT',
  OUTDENT = 'OUTDENT',

  CHAR_SELECTOR = 'CharSelector',
  CHAR = 'Char',
  ICON = 'Icon',

  DIALOG = 'Dialog',
  ADD_ROW_BEFORE = 'AddRowBefore',
  ADD_ROW_AFTER = 'AddRowAfter',
  REMOVE_ROW = 'RemoveRow',

  ADD_MC_QUESTION = 'AddMCQuestion',
  REMOVE_MC_QUESTION = 'RemoveMCQuestion',
  ADD_MC_CATEGORY = 'AddMCCategory',
  REMOVE_MC_CATEGORY = 'RemoveMCCategory',
  DRAGGABLE_BLOCK = 'DraggableBlock',

  ADD_ODD_WORD_QUESTION = 'AddOddWordQuestion',
  REMOVE_ODD_WORD_QUESTION = 'RemoveOddWordQuestion',

  TOGGLE_EXAMPLE = 'ToggleExample'
}

export interface ToolbarPlugin extends Plugin {
  show?: () => void;
  hide?: () => void;
}

export interface ToolBoxChildrenProps {
  close: (restoreEditorFocus?: boolean) => void;
  freeze: (shouldFreeze: boolean) => void;
}

export interface RenderToolbarButtonProps {
  editor: Editor;
  intl: IntlShape;
  key?: string | number;
}

export interface HtmlSerializable {
  htmlRules: () => SerializeRules;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onQuery: (query: Query, editor: Editor, next: Next) => any;
}

export interface SchemaBlockRules {
  type: SlateBlock;
  rules: Rules;
  normalizer?: Normalizer;
}

export interface SchemaInlineRules {
  type: SlateInline;
  rules: Rules;
  normalizer?: Normalizer;
}

export interface SchemaMarkPlugin {
  markRules: () => {type: SlateMark};
}

export interface SchemaBlockPlugin {
  blockRules: () => SchemaBlockRules;
}

export interface MarkClassNamesPlugin {
  getMarkClassNames: () => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onQuery: (query: Query, editor: Editor, next: Next) => any;
}

export interface InsertFragmentPlugin {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onQuery: (query: Query, editor: Editor, next: Next) => any;
  onBeforeInsertFragment: (
    event: React.ClipboardEvent,
    fragmentChange: Editor,
    change: Editor
  ) => Document | boolean | undefined | void;
}

export type PluginDisablerPredicate = (editor: Editor) => boolean;

export interface ButtonDisablerPlugin {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onQuery: (query: Query, editor: Editor, next: Next) => any;
  disableButtons: {[key in ButtonType]?: PluginDisablerPredicate};
}

export interface SchemaInlinePlugin {
  inlineRules: () => SchemaInlineRules;
}

export type NodeStyle<T extends SlateInline | SlateMark | SlateBlock> = {
  [key in T]?: CSSProperties;
};

type objectNormalizerFn = (change: Editor, error: SlateError) => void | boolean;

export interface Normalizer {
  predicate: (error: SlateError) => boolean;
  reasons: {[reason in InvalidReason]?: objectNormalizerFn | null};
}

export interface ObjectNormalizer {
  [key: string]: Normalizer | null;
}
type TypeNormalizer = {[key in SlateObject]?: Normalizer | null};
export type DocumentNormalizer = {[key in SlateBlock]: Normalizer | null} & TypeNormalizer;
export type BlockNormalizer = {[key in SlateInline]: Normalizer | null} & {
  [key in SlateBlock]?: Normalizer | null;
};
