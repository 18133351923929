import {Block, type Editor, type Value, type Document, Data} from '@englex/slate';
import {type List} from 'immutable';

import {
  type ListBlock,
  type ListItemBlock,
  type ListStyleType,
  type ListType,
  SlateBlock
} from '../../../../interface';
import {
  getFurthestListBlockOfBlock,
  getListItemBlockOfBlock,
  getNextLi,
  getFollowingListOfType,
  getPreviousLi,
  getPreviousSiblingBlockInListItem,
  getPrevSiblings
} from './utils';
import {getClosestListAncestor} from './Indentation/utils';
import {isListBlock, isListBlockOfType, isListStyleType, isListType} from '../../../../utils';

const changeListType = (
  change: Editor,
  block: ListBlock,
  type: ListType,
  listStyleType?: ListStyleType
) => {
  const descendantsLists = block.filterDescendants(isListBlock) as List<ListBlock>;
  descendantsLists.forEach(list => change.command(changeListType, list, type, listStyleType));
  change.setNodeByKey(block.key, {
    data: block.data.withMutations(d => {
      d.set('list', type);
      listStyleType ? d.set('listStyleType', listStyleType) : d.delete('listStyleType');
    })
  });
};

const moveBlockToEndOfListItem = (change: Editor, block: Block, li: ListItemBlock) => {
  change.moveNodeByKey(block.key, li.key, li.nodes.count());
};

const moveBlockAndNextSiblingsToPrevLi = (
  change: Editor,
  startBlock: Block,
  targetListItem: ListItemBlock
) => {
  // we know for sure block is in list item, otherwise it shouldn't have been unwrapped
  const listItem = getListItemBlockOfBlock(change.value.document, startBlock) as ListItemBlock;
  const blockAndNextSiblings = listItem.nodes.skipUntil((b: Block) => b.key === startBlock.key);
  // iterate over blocks in reverse order (reverse here is for optimization: do not get nodes count on each step in forEach below)
  change.withoutNormalizing(() => {
    blockAndNextSiblings.reverse().forEach((b: Block) => {
      // targetListItem stays the same here because we just move some blocks in it, and normalizer shouldn't do anything
      change.command(moveBlockToEndOfListItem, b, targetListItem);
    });
    return change;
  });
};

export const wrapBlockByListItem = (change: Editor, block: Block) => {
  change.wrapBlockByKey(block.key, {type: SlateBlock.LIST_ITEM});
};

const unwrapFromList = (change: Editor, listItem: ListItemBlock) => {
  change.unwrapBlockByKey(listItem.key, {type: SlateBlock.LIST});
};

const moveFromListsOnAllLevelsWhereIsInLastLi = (ch: Editor, block: Block) => {
  const {document} = ch.value;
  const listItem = getListItemBlockOfBlock(ch.value.document, block);
  // check if block is in listItem and this listItem is last in list
  if (!listItem || getNextLi(ch.value.document, listItem)) {
    return;
  }
  // current block is in list item, which means it has list and this list has it's own parent block
  const closestList = getClosestListAncestor(document, block) as ListBlock;
  const destinationBlock = document.getParent(closestList.key) as Block;

  const index = destinationBlock.nodes.indexOf(closestList);
  // move block from list and check the same on next level
  ch.withoutNormalizing(() => {
    ch.moveNodeByKey(block.key, destinationBlock.key, index + 1).command(
      moveFromListsOnAllLevelsWhereIsInLastLi,
      block
    );
    const listItemHasNoBlocksLeft = !(ch.value.document.getNode(listItem.key) as Block).nodes.find(
      Block.isBlock
    );
    if (listItemHasNoBlocksLeft) {
      ch.removeNodeByKey(listItem.key);
    }
  });
};

const unwrapPreviousTailBlocks = (ch: Editor, block: Block) => {
  // if there is nextLi, which means unwrapped item was last in the list, recursively get previous block and unwrap them
  // while previous block is not first block in list item
  const {document} = ch.value;
  const previousBlock = document.getPreviousBlock(block.key);
  if (!previousBlock) {
    return;
  }
  if (!getPreviousSiblingBlockInListItem(document, previousBlock)) {
    return;
  }
  // we are sure previous block is in list because it is in list item as previous check stated
  const furthestList = getFurthestListBlockOfBlock(document, previousBlock)!;
  const index = document.nodes.indexOf(furthestList);
  ch.moveNodeByKey(previousBlock.key, document.key, index + 1).command(
    unwrapPreviousTailBlocks,
    previousBlock
  );
};

const moveItemsFromEmbeddedListToParentList = (
  ch: Editor,
  embeddedList: Block,
  parentList: Block
) => {
  let index = 0;
  // parentList stays the same here because we just move some list items from embedded blocks to it, and normalizer shouldn't do anything
  embeddedList.nodes.forEach(node => node && ch.moveNodeByKey(node.key, parentList.key, ++index));
};

export const moveBlockFromListItem = (ch: Editor, block: Block) => {
  const {document} = ch.value;
  // we know for sure every block is in list item because otherwise we wouldn't be unwrapping blocks
  const listItem = getListItemBlockOfBlock(document, block) as ListItemBlock;
  const previousLi = getPreviousLi(document, listItem);
  const nextLi = getNextLi(document, listItem);
  const embeddedList = listItem.nodes.find(isListBlock);
  const isMiddleLi = previousLi && nextLi;
  const isLastLiWithEmbeddedList = previousLi && embeddedList;
  if (isMiddleLi || isLastLiWithEmbeddedList) {
    // current li is in the middle of list, meaning it has previous li and next li, or is last li of list and has
    // embedded list it it; then move current block and all it's next siblings to previous li
    ch.command(moveBlockAndNextSiblingsToPrevLi, block, previousLi);
    return;
  }

  if (!previousLi) {
    // current li is first in list; if it contains embedded list, move all blocks from this list to destination list,
    // then unwrap what's left in current li from list
    if (embeddedList) {
      // every li is always inside of list, so assert as ListBlock here
      const parentList = document.getParent(listItem.key) as ListBlock;
      ch.command(moveItemsFromEmbeddedListToParentList, embeddedList as ListBlock, parentList);
    }
    ch.command(unwrapFromList, listItem);
    return;
  }

  if (!nextLi) {
    // current li is last in list and has no embedded list; first, move current block recursively from lists on all levels
    // where it is in last li of list on this level
    ch.command(moveFromListsOnAllLevelsWhereIsInLastLi, block);

    if (!getClosestListAncestor(ch.value.document, block)) {
      // if block was in last li on all list indentation levels, now it is child of document; go through all blocks before it
      // while we don't come across first block in li and move these tail blocks to document as well;
      ch.command(unwrapPreviousTailBlocks, block);
    }

    return;
  }

  return;
};

const getNeighboringList = (change: Editor, list: ListType) => {
  const {blocks, document} = change.value;
  if (!getPreviousSiblingBlockInListItem(document, blocks.first())) {
    const previousBlock = document.getPreviousBlock(blocks.first().key);
    const listBeforeSelection =
      previousBlock && getFurthestListBlockOfBlock(document, previousBlock);
    const previousBlockIsTailBlockInItem =
      previousBlock && getPreviousSiblingBlockInListItem(document, previousBlock);
    if (
      listBeforeSelection &&
      listBeforeSelection.data.get('list') === list &&
      !previousBlockIsTailBlockInItem
    ) {
      return listBeforeSelection;
    }
  }

  return null;
};

export const getSelectedBlocksCommonList = (value: Value) => {
  const {blocks, document} = value;
  const commonList = getFurthestListBlockOfBlock(document, blocks.first());
  if (!commonList) {
    return false;
  }
  const allBlocksAreInOneList = !blocks.find(block => {
    if (!block) {
      return true;
    }
    if (getPreviousSiblingBlockInListItem(document, block)) {
      return true;
    }
    const parentList = getFurthestListBlockOfBlock(document, block);
    return !parentList || parentList.key !== commonList.key;
  });

  return allBlocksAreInOneList ? commonList : null;
};

function changeTypeOrUnwrapElements(ch: Editor, list: ListType, listStyleType?: ListStyleType) {
  const {blocks, document, startBlock} = ch.value;
  if (!startBlock) {
    return;
  }
  // every block is in one list, so startBlock is definitely in list
  const targetList = getClosestListAncestor(document, startBlock) as ListBlock;
  if (!isListType(targetList, list) || !isListStyleType(targetList, listStyleType)) {
    ch.command(changeListType, targetList, list, listStyleType);
  } else {
    blocks.forEach((block: Block) => {
      // we are just moving blocks from list item, so targetList shouldn't change
      ch.command(moveBlockFromListItem, block);
    });
  }
}

function moveToPrevListOrWrapInNew(
  ch: Editor,
  block: Block,
  list: ListType,
  listStyleType?: ListStyleType
) {
  const {value} = ch;
  const {document} = value;
  const prevBlock = document.getPreviousBlock(block.key);
  if (prevBlock) {
    const prevList = getFurthestListBlockOfBlock(document, prevBlock);
    if (prevList && isListBlockOfType(prevList, list) && isListStyleType(prevList, listStyleType)) {
      const listItem = getListItemBlockOfBlock(document, block);
      // if current block is in list item, move whole list item, else move just this block
      const blockToMove = listItem || block;
      ch.moveNodeByKey(blockToMove.key, prevList.key, prevList.nodes.size);
      return;
    }
  }
  const data = Data.create({}).withMutations(d => {
    d.set('list', list);
    if (listStyleType) {
      d.set('listStyleType', listStyleType);
    }
  });
  ch.unwrapBlockByKey(block.key, {type: SlateBlock.LIST}).wrapBlockByKey(block.key, {
    type: SlateBlock.LIST,
    data
  });
}

const isPrevBlockInCurrentList = (document: Document, block: Block) => {
  const currentList = getFurthestListBlockOfBlock(document, block);
  if (!currentList) {
    return false;
  }
  const prevBlock = document.getPreviousBlock(block.key);
  const prevBlockList = prevBlock && getFurthestListBlockOfBlock(document, prevBlock);
  return !!prevBlockList && prevBlockList.key === currentList.key;
};

const wrapTailBlockInList = (change: Editor, block: Block, previousSiblingsNumber: number) => {
  const {document} = change.value;
  // change is called from toggleWhenThisAndPrevBlockAreInSameList, which means current block is in li, so assert li here
  const li = getListItemBlockOfBlock(document, block)!;
  change.splitNodeByKey(li.key, previousSiblingsNumber);
};

const getListFromBlockAndChangeType = (
  change: Editor,
  block: Block,
  list: ListType,
  listStyleType?: ListStyleType
) => {
  const listBlock = getFurthestListBlockOfBlock(change.value.document, block);
  change.command(changeListType, listBlock, list, listStyleType);
};

const splitItemsAndChangeCreatedListType = (
  change: Editor,
  block: Block,
  listBlock: ListBlock,
  list: ListType,
  listStyleType?: ListStyleType
) => {
  const {document} = change.value;
  // change is called from toggleWhenThisAndPrevBlockAreInSameList, which means current block is in li, so assert li here
  const listItem = getListItemBlockOfBlock(document, block) as ListItemBlock;
  const indexOfListItem = getPrevSiblings(document, listItem).size;

  change
    .splitNodeByKey(listBlock.key, indexOfListItem)
    .command(getListFromBlockAndChangeType, block, list, listStyleType);
};

const toggleWhenThisAndPrevBlockAreInSameList = (
  ch: Editor,
  currentList: ListBlock,
  list: ListType,
  block: Block,
  listStyleType?: ListStyleType
) => {
  const {document} = ch.value;
  if (
    currentList.data.get('list') === list &&
    currentList.data.get('listStyleType') === listStyleType
  ) {
    const previousSiblingsNumber = getPrevSiblings(document, block).size;
    if (previousSiblingsNumber) {
      // if current block is not first block in li, split this li, making current block the first in new li.
      ch.command(wrapTailBlockInList, block, previousSiblingsNumber);
    } else {
      // if current block is first block in li, do nothing with it.
      return;
    }
  } else {
    // button pressed is list of different type,
    // split all list items from current list after current item and change type of created list, then unwrap
    // previous tail blocks
    ch.command(splitItemsAndChangeCreatedListType, block, currentList, list, listStyleType);

    ch.command(unwrapPreviousTailBlocks, block);
  }
};

export const toggleList = (ch: Editor, list: ListType, listStyleType?: ListStyleType) => {
  const {blocks} = ch.value;

  // common list is common furthest ancestor list for all blocks, means all blocks are in one list
  const commonList = getSelectedBlocksCommonList(ch.value);

  // neighboring list is list right before the start of selection
  const neighboringList = getNeighboringList(ch, list);

  // if common list exists and there is no neighboring list or neighboring list is common list, change list type
  // or unwrap elements, depending on pressed button
  if (commonList && (!neighboringList || commonList.key === neighboringList.key)) {
    changeTypeOrUnwrapElements(ch, list, listStyleType);
  } else {
    blocks.forEach((block: Block) => {
      const {document} = ch.value;
      const currentList = getClosestListAncestor(document, block);
      const prevBlockInCurrentList = isPrevBlockInCurrentList(document, block);

      if (currentList && prevBlockInCurrentList) {
        // current block and previous block are both in the same list
        ch.command(
          toggleWhenThisAndPrevBlockAreInSameList,
          currentList,
          list,
          block,
          listStyleType
        );
      } else {
        // if block is not in list, or previous block is not in the same list as current, move block or whole list item
        // to previous list, else unwrap it from current list and wrap in new
        ch.command(moveToPrevListOrWrapInNew, block, list, listStyleType);
      }
    });
    const {endBlock} = ch.value;
    if (!commonList && endBlock) {
      const {document} = ch.value;
      const endBlockList = getFurthestListBlockOfBlock(document, endBlock) as ListBlock;
      const followingList = getFollowingListOfType(document, endBlockList);
      if (followingList && isListType(followingList, list)) {
        // after all blocks have been toggled, merge last of them to following list, if one exists
        ch.mergeNodeByKey(followingList.key);
      }
    }
  }
};
