import {
  type Editor as ReactEditor,
  getEventRange,
  getEventTransfer,
  type Plugin
} from '@englex/slate-react';
import {type Block, type Document, type Editor, type Next} from '@englex/slate';
import type React from 'react';

import {SlateBlock} from '../../interface';
import {isBlockOfType} from '../../utils';

export default class ImageDropResolver implements Plugin {
  public onDrop = (event: React.DragEvent, editor: ReactEditor & Editor, next: Next) => {
    const {fragment} = getEventTransfer(event);
    if (!fragment || !fragment.getBlocksByType(SlateBlock.IMAGE).size) {
      return next();
    }
    if (imageTargetIsValid(event, editor)) {
      return next();
    }
  };
}

export const imageTargetIsValid = (event: React.DragEvent, editor: Editor): boolean => {
  const target = getEventRange(event, editor);
  if (!target) {
    return false;
  }
  if (editor.value.document.getInlinesAtRangeAsArray(target).length) {
    return false;
  }
  if (!target.anchor.path) {
    return true;
  }
  const ancestors = editor.value.document.getAncestors(target.anchor.path);
  if (ancestors) {
    const forbiddenAncestors = ancestors.filter((b: Block | Document) => {
      if (b.object === 'block') {
        return !isBlockOfType(b, SlateBlock.DEFAULT);
      }
      return b.object !== 'document';
    });
    if (forbiddenAncestors.size) {
      return false;
    }
  }
  return true;
};
