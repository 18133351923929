import {type Editor, type Block, type Mark} from '@englex/slate';
import {List} from 'immutable';

import {ButtonType} from '../interface';
import {buttonTitle} from './i18n';
import ToolbarButton from '../ToolbarButton';
import {SlateBlock} from '../../../interface';
import {getActiveMarks} from '../queries';
import {removeMark} from '../changes';

class ClearFormatting extends ToolbarButton {
  public type = ButtonType.CLEAR_FORMATTING;
  public title = buttonTitle.ClearFormatting;
  public icon = 'virc-clear-format';
  public shortcut = 'mod+\\';

  protected toggleChange = (change: Editor) => {
    const {value} = change;
    const {selection} = value;
    if (!value.endBlock) {
      return;
    }
    const endBlockLength = value.endBlock.text.length;
    let blocks: List<Block> = List(value.blocks);
    if (selection?.start.offset !== 0) {
      blocks = blocks.remove(0);
    }
    if (selection?.end.offset !== endBlockLength) {
      blocks = blocks.pop();
    }
    if (blocks.size) {
      blocks
        .filter((b: Block) => b.type !== SlateBlock.IMAGE)
        .forEach((b: Block) => {
          if (b.data.get('style')) {
            change.setNodeByKey(b.key, {
              type: b.type,
              data: b.data.delete('style')
            });
          }
        });
    }
    value.marks.forEach((m: Mark) => change.command(removeMark, m));
  };

  public isDisabled = (editor: Editor): boolean =>
    super.isDisabled(editor) ||
    (!!editor.value.selection?.isCollapsed && getActiveMarks(editor).size === 0);
}

export default ClearFormatting;
