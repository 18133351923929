import {type Editor, type GenericQuery, type Mark, type Next} from '@englex/slate';
import {type DeserializeFn, type SerializeRules} from '@englex/slate-html-serializer';

import ToolbarButton from '../ToolbarButton';
import {getHtmlRules, type SlateMark} from '../../../interface';
import {type HtmlSerializable, type SchemaMarkPlugin} from '../interface';
import {toggleMark} from '../changes';
import {getActiveMarks} from '../queries';
import './Mark.scss';

abstract class ToggleMark extends ToolbarButton implements HtmlSerializable, SchemaMarkPlugin {
  public abstract mark: SlateMark;
  public wrapper: undefined;
  public fromHtml?: DeserializeFn;
  public markRules = () => ({type: this.mark});

  public htmlRules = (): SerializeRules => [
    {
      deserialize: this.fromHtml
    }
  ];

  protected toggleChange = (editor: Editor) => {
    editor.command(toggleMark, this.mark);
  };

  public isActive = (editor: Editor): boolean => {
    return getActiveMarks(editor).some((mark: Mark) => mark.type === this.type);
  };

  public onQuery = (query: GenericQuery<never>, editor: Editor, next: Next) => {
    if (query.type === getHtmlRules) {
      const rules: SerializeRules = next() || [];
      return this.htmlRules().concat(rules);
    }
    return this.onToolbarButtonQuery(query, editor, next);
  };
}

export default ToggleMark;
