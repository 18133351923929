import {type Editor as ReactEditor, type Plugin} from '@englex/slate-react';
import {
  type Block,
  Document,
  type Editor,
  type Next,
  type Query,
  type SlateError,
  type Value
} from '@englex/slate';
import type React from 'react';

import isShortcut from 'helpers/shortcut';

import ToolbarButton from '../../ToolbarButton';
import {
  type ButtonDisablerPlugin,
  ButtonType,
  type PluginDisablerPredicate,
  type SchemaBlockPlugin,
  type SchemaBlockRules
} from '../../interface';
import {
  getFollowingListOfType,
  getFurthestListBlockOfBlock,
  getListItemBlockOfBlock,
  getPreviousSiblingBlockInListItem,
  listIsActive,
  listIsDisabled
} from './utils';
import {moveBlockFromListItem, toggleList, wrapBlockByListItem} from './changes';
import {
  getAncestorListOfDepth,
  getClosestListAncestor,
  getListIndentLevel
} from './Indentation/utils';
import {isBlockOfType, isInline, isInlineOfType, isListBlock, isListType} from '../../../../utils';
import {enumContainsValue} from '../../../../../../helpers/enum';
import {
  getToolbarButtonDisablers,
  getToolbarButtons,
  type ListBlock,
  type ListItemBlock,
  ListStyleType,
  ListType,
  SlateBlock,
  SlateInline,
  SlateObject
} from '../../../../interface';
import {type Outdent} from './Indentation/Outdent';
import {copyFragment} from '../../utils';

abstract class List
  extends ToolbarButton
  implements SchemaBlockPlugin, ButtonDisablerPlugin, Plugin
{
  public abstract list: ListType;
  public listStyleType?: ListStyleType;
  public block = SlateBlock.LIST;
  public type = ButtonType.ORDERED_LIST;
  protected shouldUnwrapEmptyLiOnEnter = true;

  public disableButtons = {
    [ButtonType.DIALOG]: (editor: Editor) =>
      !editor.value.startBlock ||
      !!getListItemBlockOfBlock(editor.value.document, editor.value.startBlock)
  };

  public blockRules = (): SchemaBlockRules => ({
    type: this.block,
    rules: {
      data: {
        list: (s: ListType) => enumContainsValue(ListType, s),
        listStyleType: (s: string) => enumContainsValue(ListStyleType, s) || !s,
        style: () => true
      },
      nodes: [
        {
          match: [{type: SlateBlock.LIST_ITEM}],
          min: 1
        }
      ]
    },
    normalizer: {
      predicate: ({node}: SlateError) => isListBlock(node),
      reasons: {
        child_type_invalid: (change: Editor, error: SlateError) => {
          const {child, node} = error;
          if (
            isBlockOfType(child!, SlateBlock.DEFAULT) ||
            isBlockOfType(child!, SlateBlock.IMAGE)
          ) {
            change.command(wrapBlockByListItem, child);
            return true;
          }
          if (child && child.object === SlateObject.TEXT) {
            change.removeNodeByKey(node.key);
            return true;
          }
          return;
        },
        child_min_invalid: (change: Editor, {node}: SlateError) => {
          change.removeNodeByKey(node.key);
          return true;
        }
      }
    }
  });

  public onQuery = (query: Query, editor: Editor, next: Next) => {
    if (query.type === getToolbarButtonDisablers) {
      const predicates: PluginDisablerPredicate[] = next() || [];
      const buttonType: ButtonType = query.args[0];
      if (buttonType && Object.keys(this.disableButtons).includes(buttonType)) {
        predicates.unshift(this.disableButtons[buttonType]);
      }
      return predicates;
    }
    return this.onToolbarButtonQuery(query, editor, next);
  };

  public onCopy = (event: React.ClipboardEvent, editor: ReactEditor & Editor, next: Next) => {
    const handled = this.handleCopy(event, editor);
    return !handled ? next() : undefined;
  };

  public handleCopy = (event: React.ClipboardEvent, change: Editor) => {
    const {startBlock, document, endBlock, blocks} = change.value;
    if (!startBlock || !endBlock || !getListItemBlockOfBlock(document, startBlock)) {
      return;
    }

    if (startBlock.key === endBlock.key) {
      // by default, slate copies content wrapped in all ancestor blocks, but if selection is in li and covers
      // only one block, don't copy anything outside of this block, just inlines and texts in it
      this.copyTextFromSingleLi(change, event);
      return true;
    }

    const indentLevels = blocks.map(block => {
      const closestList = getClosestListAncestor(document, block!)!;
      return closestList ? getListIndentLevel(document, closestList) : 0;
    });
    const minIndentLevel = indentLevels.min();
    if (minIndentLevel > 0) {
      // if all indent levels are more then 0, copy only from minimal indent level, so that created list doesn't have empty
      // blocks of lower levels
      this.copyFromIndentLevel(change, event, minIndentLevel);
      return true;
    }

    return;
  };

  public onCut = (event: React.ClipboardEvent, change: ReactEditor & Editor, next: Next) => {
    if (this.handleCopy(event, change)) {
      window.requestAnimationFrame(() => {
        change.command((newChange: Editor) => newChange.delete());
      });
      return;
    }
    return next();
  };

  public toggleChange = (change: Editor) =>
    change.command(toggleList, this.list, this.listStyleType);

  public onKeyDown = (event: React.KeyboardEvent, change: ReactEditor & Editor, next: Next) => {
    if (isShortcut(event, this.shortcut) && !this.isDisabled(change)) {
      change.command(this.toggleChange);
      return;
    }

    const {value} = change;
    const {startBlock, document, endBlock} = value;
    if (!startBlock || !endBlock) {
      event.preventDefault();
      return;
    }
    const mayBeList = getClosestListAncestor(document, startBlock);

    if (
      mayBeList &&
      isListBlock(mayBeList) &&
      mayBeList.data.get('list') === this.list &&
      mayBeList.data.get('listStyleType') === this.listStyleType
    ) {
      // mayBeList is list block, so current block is definitely in li
      const currentLi = getListItemBlockOfBlock(document, startBlock) as ListItemBlock;

      const blockHasInline = !!startBlock.nodes.find(node => !!node && isInline(node));

      if (isShortcut(event, 'shift+enter')) {
        change.insertText('\n');
        return;
      }

      if (isShortcut(event, 'enter')) {
        return this.onEnter(change, startBlock, event, change, blockHasInline, next);
      }

      if (isShortcut(event, 'backspace')) {
        return this.onBackspace(change, currentLi, startBlock, endBlock, next);
      }

      if (isShortcut(event, 'delete')) {
        return this.onDelete(change, startBlock, endBlock, blockHasInline, next);
      }
    }

    const nextBlock = document.getNextBlock(startBlock.key);

    if (isShortcut(event, 'delete') && nextBlock) {
      // if next block is in list, and current block is not in the same list, we should unwrap next block
      if (this.shouldUnwrapNextBlock(value, nextBlock, startBlock)) {
        change.command(moveBlockFromListItem, nextBlock);
        if (!!getListItemBlockOfBlock(document, startBlock)) {
          change.deleteForward(1);
        } else {
          // fixes issue of reverting this operation when start block is not in list
          const newValue = change.value;

          if (!newValue.startBlock) return;

          const unwrappedBlock = newValue.document.getNextBlock(newValue.startBlock.key);
          change.mergeNodeByKey(unwrappedBlock!.key);
        }
        return;
      }
    }

    return next();
  };

  protected onBackspace = (
    change: Editor,
    startListItem: Block,
    startBlock: Block,
    endBlock: Block,
    next: Next
  ): boolean | void => {
    const {value} = change;

    const anchorAtStartOfItem = value.selection?.anchor.isAtStartOfNode(startListItem);
    const focusAtStartOfItem = value.selection?.focus.isAtStartOfNode(startListItem);
    if (anchorAtStartOfItem && focusAtStartOfItem) {
      // if cursor is at start of item, unwrap this item
      change.command(moveBlockFromListItem, value.startBlock);
      return;
    }

    if (this.deleteWithAfterAction(change, startListItem, startBlock, endBlock)) {
      return;
    }

    return next();
  };

  protected onDelete = (
    change: Editor,
    startBlock: Block,
    endBlock: Block,
    blockHasInline: boolean,
    next: Next
  ): boolean | void => {
    const {value} = change;
    const {document} = value;

    const blockIsEmpty = !startBlock.text.length && !blockHasInline;

    if (blockIsEmpty) {
      const isFirstBlockInLi = !getPreviousSiblingBlockInListItem(document, startBlock);

      if (isFirstBlockInLi) {
        // if delete pressed in first block of li, which is empty, unwrap this block
        change.command(toggleList, this.list, this.listStyleType);
      } else {
        // if delete pressed in tail block of li, which is empty, delete this block and then move cursor to the start of next block
        change.removeNodeByKey(startBlock.key).moveFocusForward(1).moveAnchorForward(1);
      }
      return;
    }

    if (
      this.deleteWithAfterAction(
        change,
        getListItemBlockOfBlock(document, startBlock) as Block,
        startBlock,
        endBlock
      )
    ) {
      return;
    }

    return next();
  };

  protected onEnter = (
    change: Editor,
    startBlock: Block,
    event: React.KeyboardEvent,
    editor: ReactEditor & Editor,
    blockHasInline: boolean,
    next: Next
  ): boolean | void => {
    const {value} = change;
    const {document, startInline} = value;

    const cursorIsInIcon =
      blockHasInline && startInline && isInlineOfType(startInline, SlateInline.ICON);
    // if cursor is in one of icons in this block, do nothing
    if (cursorIsInIcon) {
      return next();
    }

    // if outdent plugin is present in this editor,
    // first check if should outdent embedded list item on enter, and if so, do nothing in this handler
    const outdentPlugin = editor
      .query<ToolbarButton[]>(getToolbarButtons)
      .find((plugin: ToolbarButton) => plugin.type === ButtonType.OUTDENT) as Outdent | undefined;
    if (outdentPlugin && outdentPlugin.handleKeyDown!(event, editor)) {
      return;
    }

    const previousSiblingBlock = getPreviousSiblingBlockInListItem(document, startBlock);

    // even if text is empty, we still have to check for icons to consider block empty
    const blockIsEmpty = !startBlock.text.length && !blockHasInline;

    if (!previousSiblingBlock && blockIsEmpty && this.shouldUnwrapEmptyLiOnEnter) {
      // if block is empty and is first block in li, call toggleList, which will unwrap current li
      change.command(toggleList, this.list, this.listStyleType);
    } else {
      // if block has no previous sibling block in li, we should make the new li, so split with depth 2
      // if block has previous sibling block, we should make the new p in li, so just split p with depth 1
      const splitDepth = !previousSiblingBlock ? 2 : 1;
      change.splitBlock(splitDepth);
    }
    return;
  };

  public isDisabled(editor: Editor) {
    const {document, startBlock} = editor.value;
    if (super.isDisabled(editor) || !startBlock) {
      return true;
    }
    return listIsDisabled(document, startBlock, this.list);
  }

  public isActive = (editor: Editor) => {
    if (this.isDisabled(editor)) {
      return false;
    }

    return listIsActive(editor, {
      listStyleType: this.listStyleType,
      list: this.list,
      checkListStyleType: true
    });
  };

  protected shouldUnwrapNextBlock = (value: Value, nextBlock: Block, startBlock: Block) => {
    const {document, selection} = value;
    const nextBlockList = getFurthestListBlockOfBlock(document, nextBlock);
    if (!nextBlockList) {
      return;
    }

    const thisBlockList = getFurthestListBlockOfBlock(document, startBlock);

    if (!nextBlockList) {
      return false;
    }
    const thisBlockNotInList = !thisBlockList;
    const nextBlockInAnotherList = thisBlockList && nextBlockList.key !== thisBlockList.key;
    const selectionAtEndOfBlock = selection?.start.isAtEndOfNode(startBlock);
    const blockIsParagraph = isBlockOfType(startBlock, SlateBlock.DEFAULT);

    return (
      (nextBlockInAnotherList || thisBlockNotInList) && selectionAtEndOfBlock && blockIsParagraph
    );
  };

  private deleteWithAfterAction = (
    change: Editor,
    startListItem: ListItemBlock,
    startBlock: Block,
    endBlock: Block
  ) => {
    const {value} = change;
    const anchorAtStartOfItem = value.selection?.anchor.isAtStartOfNode(startListItem);
    const focusAtStartOfItem = value.selection?.focus.isAtStartOfNode(startListItem);

    const listBlockOfEndBlock = getFurthestListBlockOfBlock(value.document, endBlock);
    if (!listBlockOfEndBlock && (anchorAtStartOfItem || focusAtStartOfItem)) {
      // if anchor or focus is at start of the first list item in selection, and last block in selection is not in list,
      // delete selected content and then unwrap what's left
      change.delete().command(toggleList, this.list, this.listStyleType);
      return true;
    }

    const listBlockOfStartBlock = getFurthestListBlockOfBlock(value.document, startBlock)!;
    if (listBlockOfEndBlock && listBlockOfStartBlock.key !== listBlockOfEndBlock.key) {
      // if selection starts in one list and finishes in another, merge two lists after delete
      change.delete();
      const endBlockAfterDelete = change.value.endBlock;
      if (!endBlockAfterDelete) {
        return true;
      }
      const endBlockList = getFurthestListBlockOfBlock(
        value.document,
        endBlockAfterDelete
      ) as ListBlock;
      const followingList = getFollowingListOfType(value.document, endBlockList);
      if (followingList && isListType(followingList, this.list)) {
        // after all blocks have been toggled, merge last of them to following list, if one exists
        change.mergeNodeByKey(followingList.key);
      }

      return true;
    }

    return false;
  };

  private copyFromIndentLevel = (
    change: Editor,
    event: React.ClipboardEvent,
    minIndentLevel: number
  ) => {
    const {document, selection} = change.value;
    const range = document.createRange({anchor: selection?.anchor, focus: selection?.focus});
    const fragmentWithAllLevels = document.getFragmentAtRange(range);
    const closestCommonList = getAncestorListOfDepth(
      fragmentWithAllLevels,
      fragmentWithAllLevels.getBlocks().first(),
      minIndentLevel
    )!;
    const minIndentLevelFragment = Document.create([closestCommonList]);
    copyFragment(event, minIndentLevelFragment);
  };

  private copyTextFromSingleLi = (change: Editor, event: React.ClipboardEvent) => {
    const {document, selection} = change.value;
    const range = document.createRange({anchor: selection?.anchor, focus: selection?.focus});
    const fragmentWithAllLevels = document.getFragmentAtRange(range);
    const oneLevelFragment = Document.create([fragmentWithAllLevels.getBlocks().first()]);
    copyFragment(event, oneLevelFragment);
  };
}
export default List;
