import React from 'react';
import {type WrappedComponentProps, injectIntl} from 'react-intl';
import {type Plugin} from '@englex/slate-react';
import {type Editor} from '@englex/slate';

import ToolbarButton from '../plugins/ToolbarButton';
import './StaticToolbar.scss';

interface OwnProps {
  show?: boolean;
  className?: string;
  plugins: Plugin[];
  editor: Editor;
  hideOnEditorBlur?: boolean;
}

type Props = OwnProps & WrappedComponentProps;

class SlateToolbar extends React.Component<Props> {
  private buttons: ToolbarButton[];
  constructor(props: Props) {
    super(props);
    this.buttons = props.plugins.filter(
      (p: unknown) => p instanceof ToolbarButton
    ) as ToolbarButton[];
  }

  public render() {
    const {className, editor, intl, show, hideOnEditorBlur} = this.props;
    if (!show || editor.readOnly || (hideOnEditorBlur && !editor.value.selection?.isFocused)) {
      return null;
    }

    return (
      <div className={className}>
        {this.buttons.map((plugin: ToolbarButton, key: number) =>
          plugin.renderToolbarButton({key, editor, intl})
        )}
      </div>
    );
  }
}

export default injectIntl(SlateToolbar);
