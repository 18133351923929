import React from 'react';
import {type Editor as ReactEditor, type Plugin} from '@englex/slate-react';
import {type IntlShape, type MessageDescriptor} from 'react-intl';
import {type Editor, type GenericQuery, type Next, type Query} from '@englex/slate';
import classNames from 'classnames';

import {
  getEditorId,
  getToolbarButtonDisablers,
  getToolbarButtons,
  getToolbarPortalId
} from 'components/Slate/interface';
import isShortcut, {renderShortcut, type Shortcut} from 'helpers/shortcut';

import Icon from '../../../Icon';
import {
  type ButtonType,
  type PluginDisablerPredicate,
  type RenderToolbarButtonProps,
  type ToolbarPlugin
} from './interface';

import './ToolbarButton.scss';

export interface ToolbarButtonOptions {
  toolbar?: ToolbarPlugin;
  shouldHide?: () => boolean;
}

abstract class ToolbarButton implements Plugin {
  public editorId: string;
  public abstract type: ButtonType;
  public abstract title?: MessageDescriptor;
  public abstract icon?: string;
  public iconActive?: string;
  public titleActive?: MessageDescriptor;
  public plugins?: Plugin[];
  public label?: MessageDescriptor = undefined;
  public shortcut?: Shortcut;
  public toolbar?: ToolbarPlugin;
  protected style?: React.CSSProperties;
  protected abstract toggleChange?: (change: Editor) => void;
  // some buttons in inline-toolbar should never disabled but their render method is not called on every
  // change in editor, so manually add this prop to them
  protected neverDisable?: boolean;

  private disablers?: PluginDisablerPredicate[];
  private toolbarPortalId?: string | null;
  private shouldHide?: () => boolean;

  public onKeyDown = (
    event: React.KeyboardEvent,
    editor: ReactEditor & Editor,
    next: Next
  ): boolean | void => {
    if (!isShortcut(event, this.shortcut) || !this.toggleChange || this.isDisabled(editor)) {
      return next();
    }

    event.preventDefault();
    editor.command(this.toggleChange);

    return;
  };

  public getIcon = (editor: Editor): string | undefined => {
    return this.iconActive && this.isActive(editor) ? this.iconActive : this.icon;
  };

  public constructor(options?: ToolbarButtonOptions) {
    if (options) {
      this.toolbar = options.toolbar;
      this.shouldHide = options.shouldHide;
    }
  }

  public renderToolbarButton(props: RenderToolbarButtonProps): React.ReactNode {
    const {editor} = props;
    if (this.shouldHide && this.shouldHide()) {
      return null;
    }

    const classes = classNames('slate-toolbar-button', this.type, this.getClassNames(), {
      active: this.isActive(editor),
      labeled: !!this.label,
      disabled: this.isDisabled(editor),
      hidden: this.shouldHide && this.shouldHide()
    });

    return (
      <button
        type="button"
        className={classes}
        key={props.key}
        title={this.renderTitle(props)}
        onMouseDown={this.mouseDownHandler(editor)}
        onMouseUp={this.onButtonMouseUp}
      >
        {this.renderIcon(editor)}
        {this.renderLabel(editor, props.intl)}
      </button>
    );
  }

  protected handleClick = (e: React.MouseEvent<HTMLButtonElement>, editor: Editor) => {
    if (this.toggleChange) {
      editor.command(this.toggleChange);
      // if button belongs to nested toolbar, hide it
      if (this.toolbar && this.toolbar.hide) {
        this.toolbar.hide();
      }
    }
  };

  protected renderTitle = (props: RenderToolbarButtonProps): string | undefined => {
    const title = this.getTitle(props.editor);
    const localizedTitle = title ? props.intl.formatMessage(title) : undefined;

    if (this.shortcut && localizedTitle) {
      return `${localizedTitle} (${renderShortcut(this.shortcut)})`;
    }
    return localizedTitle;
  };

  protected getClassNames = (): string => {
    return '';
  };

  protected getTitle = (editor: Editor): MessageDescriptor | undefined => {
    return this.titleActive && this.isActive(editor) ? this.titleActive : this.title;
  };

  protected getLabel = (): MessageDescriptor | undefined => {
    return this.label;
  };

  public onQuery = (query: GenericQuery<never>, editor: Editor, next: Next) => {
    return this.onToolbarButtonQuery(query, editor, next);
  };

  public isActive = (editor: Editor): boolean => {
    return false;
  };

  public isDisabled(editor: Editor): boolean {
    if (this.neverDisable) {
      return false;
    }

    if (!this.disablers) {
      this.disablers =
        editor.query<PluginDisablerPredicate[] | undefined, [ButtonType]>(
          getToolbarButtonDisablers,
          this.type
        ) || [];
    }

    const disabledByOtherPlugin = !!this.disablers.find(disabler => disabler(editor));
    const hidden = Boolean(this.shouldHide && this.shouldHide());
    return disabledByOtherPlugin || editor.readOnly || editor.value.selection?.isBlurred || hidden;
  }

  protected onButtonMouseDown(e: React.MouseEvent<HTMLButtonElement>, editor: Editor) {
    e.preventDefault();

    if (this.isDisabled(editor)) {
      return;
    }

    if (this.handleClick) {
      this.handleClick(e, editor);
    }
  }

  protected onButtonMouseUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  protected renderIcon = (editor: Editor): React.ReactNode => {
    const icon = this.getIcon(editor);
    return icon ? <Icon name={icon} /> : null;
  };

  /*
   * Tooltip container is toolbar portal ID
   * if toolbar is rendered in portal - returns undefined (rc-tooltip will be rendered as the last tag inside document body)
   * if it does not exist - editor container node will be used
   */
  protected getTooltipContainer = (editor: Editor): (() => HTMLElement) | undefined => {
    this.toolbarPortalId = this.toolbarPortalId || editor.query<string | null>(getToolbarPortalId);
    if (this.toolbarPortalId) {
      return undefined;
    }
    if (!this.editorId) {
      this.editorId = editor.query<string>(getEditorId);
    }
    return () => document.getElementById(this.editorId)!;
  };

  private renderLabel = (editor: Editor, intl: IntlShape): React.ReactNode => {
    const {label} = this;
    if (!label) {
      return;
    }
    return intl.formatMessage(label);
  };

  protected onToolbarButtonQuery = (
    query: Query,
    editor: Editor,
    next: () => ToolbarButton[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any => {
    if (query.type === getToolbarButtons) {
      const [mainToolbarButtonsOnly] = query.args;
      const buttons: ToolbarButton[] = next() || [];
      if (!mainToolbarButtonsOnly || !this.toolbar) {
        buttons.unshift(this);
      }
      return buttons;
    }
    return next();
  };

  private mouseDownHandler = (editor: Editor) => (e: React.MouseEvent<HTMLButtonElement>) => {
    this.onButtonMouseDown(e, editor);
  };
}

export default ToolbarButton;
