import {type Editor} from '@englex/slate';

import ToolbarButton from '../../ToolbarButton';
import {ButtonType} from '../../interface';
import {buttonTitle} from '../i18n';

class Undo extends ToolbarButton {
  public type = ButtonType.UNDO;
  public icon = 'undo';
  public title = buttonTitle.Undo;
  public shortcut = 'mod+z';

  protected toggleChange = (change: Editor) => change.undo();

  public isDisabled(editor: Editor) {
    const undos = editor.value.data.get('undos');
    return editor.readOnly || !editor.value.selection?.isFocused || !undos || !undos.size;
  }
}
export default Undo;
