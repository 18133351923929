import {defineMessages} from 'react-intl';

import {ButtonType} from '../interface';

export const buttonTitle = defineMessages({
  [ButtonType.BOLD]: {
    id: 'Slate.Toolbar.Button.Bold.Title'
  },
  [ButtonType.ITALIC]: {
    id: 'Slate.Toolbar.Button.Italic.Title'
  },
  [ButtonType.UNDERLINE]: {
    id: 'Slate.Toolbar.Button.Underline.Title'
  },
  [ButtonType.STRIKE_THROUGH]: {
    id: 'Slate.Toolbar.Button.StrikeThrough.Title'
  },
  [ButtonType.GAP_FILL_INPUT]: {
    id: 'Slate.Toolbar.Button.GapFill.Title'
  },
  [ButtonType.GAP_FILL_DROP_DOWN]: {
    id: 'Slate.Toolbar.Button.GapFill.Title'
  },
  [ButtonType.GAP_FILL_DND]: {
    id: 'Slate.Toolbar.Button.GapFill.Title'
  },
  [ButtonType.GAP_FILL_DND_INPUT]: {
    id: 'Slate.Toolbar.Button.GapFill.Title'
  },
  [ButtonType.TEXT_ALIGNMENT]: {
    id: 'Slate.Toolbar.Button.TextAlignment'
  },
  [ButtonType.TEXT_ALIGN_LEFT]: {
    id: 'Slate.Toolbar.Button.TextAlignLeft'
  },
  [ButtonType.TEXT_ALIGN_CENTER]: {
    id: 'Slate.Toolbar.Button.TextAlignCenter'
  },
  [ButtonType.TEXT_ALIGN_RIGHT]: {
    id: 'Slate.Toolbar.Button.TextAlignRight'
  },
  [ButtonType.TEXT_ALIGN_JUSTIFY]: {
    id: 'Slate.Toolbar.Button.TextAlignJustify'
  },
  [ButtonType.UNDO]: {
    id: 'Slate.Toolbar.Button.Undo'
  },
  [ButtonType.REDO]: {
    id: 'Slate.Toolbar.Button.Redo'
  },
  [ButtonType.IMAGE]: {
    id: 'Slate.Toolbar.Button.Image'
  },
  [ButtonType.IMAGE_TEXT_WRAP_LEFT]: {
    id: 'Slate.Toolbar.Button.ImageTextWrapLeft'
  },
  [ButtonType.IMAGE_TEXT_WRAP_CENTER]: {
    id: 'Slate.Toolbar.Button.ImageTextWrapCenter'
  },
  [ButtonType.IMAGE_TEXT_WRAP_RIGHT]: {
    id: 'Slate.Toolbar.Button.ImageTextWrapRight'
  },
  [ButtonType.IMAGE_RESIZE]: {
    id: 'Slate.Toolbar.Button.ImageResize'
  },
  [ButtonType.FONT_SIZE]: {
    id: 'Slate.Toolbar.Button.FontSize'
  },
  [ButtonType.FONT_SIZE_SMALLER]: {
    id: 'Slate.Toolbar.Button.FontSizeSmaller'
  },
  [ButtonType.FONT_SIZE_SMALLEST]: {
    id: 'Slate.Toolbar.Button.FontSizeSmallest'
  },
  [ButtonType.FONT_SIZE_SMALL]: {
    id: 'Slate.Toolbar.Button.FontSizeSmall'
  },
  [ButtonType.FONT_SIZE_DEFAULT]: {
    id: 'Slate.Toolbar.Button.FontSizeDefault'
  },
  [ButtonType.FONT_SIZE_LARGE]: {
    id: 'Slate.Toolbar.Button.FontSizeLarge'
  },
  [ButtonType.FONT_SIZE_LARGEST]: {
    id: 'Slate.Toolbar.Button.FontSizeLargest'
  },
  [ButtonType.COLOR]: {
    id: 'Slate.Toolbar.Button.Color'
  },
  [ButtonType.COLOR_ORANGE]: {
    id: 'Slate.Toolbar.Button.Orange'
  },
  [ButtonType.COLOR_YELLOW]: {
    id: 'Slate.Toolbar.Button.Yellow'
  },
  [ButtonType.COLOR_RED]: {
    id: 'Slate.Toolbar.Button.Red'
  },
  [ButtonType.COLOR_BROWN]: {
    id: 'Slate.Toolbar.Button.Brown'
  },
  [ButtonType.COLOR_BLUE]: {
    id: 'Slate.Toolbar.Button.Blue'
  },
  [ButtonType.COLOR_GREEN]: {
    id: 'Slate.Toolbar.Button.Green'
  },
  [ButtonType.COLOR_PURPLE]: {
    id: 'Slate.Toolbar.Button.Purple'
  },
  [ButtonType.HIGHLIGHT]: {
    id: 'Slate.Toolbar.Button.Highlight'
  },
  [ButtonType.READONLY]: {
    id: 'Slate.Toolbar.Button.Readonly'
  },
  [ButtonType.ORDERED_LIST]: {
    id: 'Slate.Toolbar.Button.OrderedList'
  },
  [ButtonType.ORDERED_ALPHA]: {
    id: 'Slate.Toolbar.Button.OrderedListAlpha'
  },
  [ButtonType.ORDERED_DECIMAL]: {
    id: 'Slate.Toolbar.Button.OrderedListDecimal'
  },
  [ButtonType.UNORDERED_LIST]: {
    id: 'Slate.Toolbar.Button.UnorderedList'
  },
  [ButtonType.INDENT]: {
    id: 'Slate.Toolbar.Button.Indent'
  },
  [ButtonType.OUTDENT]: {
    id: 'Slate.Toolbar.Button.Outdent'
  },
  [ButtonType.CHAR_SELECTOR]: {
    id: 'Slate.Toolbar.Button.CharSelector'
  },
  [ButtonType.LINK]: {
    id: 'Slate.Toolbar.Button.Link'
  },
  [ButtonType.CLEAR_FORMATTING]: {
    id: 'Slate.Toolbar.Button.ClearFormatting'
  },
  [ButtonType.FORMAT_PAINTER]: {
    id: 'Slate.Toolbar.Button.FormatPainter'
  },
  [ButtonType.DIALOG]: {
    id: 'Slate.Toolbar.Button.InsertDialog'
  },
  [ButtonType.ADD_ROW_BEFORE]: {
    id: 'Slate.Toolbar.Button.AddRowBefore'
  },
  [ButtonType.ADD_ROW_AFTER]: {
    id: 'Slate.Toolbar.Button.AddRowAfter'
  },
  [ButtonType.REMOVE_ROW]: {
    id: 'Slate.Toolbar.Button.RemoveRow'
  },
  [ButtonType.ADD_MC_QUESTION]: {
    id: 'Slate.Toolbar.Button.AddMCQuestion'
  },
  [ButtonType.REMOVE_MC_QUESTION]: {
    id: 'Slate.Toolbar.Button.RemoveMCQuestion'
  },
  [ButtonType.ADD_MC_CATEGORY]: {
    id: 'Slate.Toolbar.Button.AddCategory'
  },
  [ButtonType.REMOVE_MC_CATEGORY]: {
    id: 'Slate.Toolbar.Button.DeleteCategory'
  },
  [ButtonType.DRAGGABLE_BLOCK]: {
    id: 'Slate.Toolbar.Button.DraggingBlock'
  },
  [ButtonType.ADD_ODD_WORD_QUESTION]: {
    id: 'Slate.Toolbar.Button.AddOddWordQuestion'
  },
  [ButtonType.REMOVE_ODD_WORD_QUESTION]: {
    id: 'Slate.Toolbar.Button.RemoveOddWordQuestion'
  },
  [ButtonType.TOGGLE_EXAMPLE]: {
    id: 'Common.Example'
  }
});

export const buttonTitleActive = defineMessages({
  [ButtonType.READONLY]: {
    id: 'Slate.Toolbar.Button.Readonly.Active'
  },
  [ButtonType.LINK]: {
    id: 'Slate.Toolbar.Button.Link.Active'
  }
});

export const buttonLabel = defineMessages({
  [ButtonType.GAP_FILL_INPUT]: {
    id: 'Slate.Toolbar.Button.GapFill.Label'
  },
  [ButtonType.GAP_FILL_DROP_DOWN]: {
    id: 'Slate.Toolbar.Button.GapFill.Label'
  },
  [ButtonType.GAP_FILL_DND]: {
    id: 'Slate.Toolbar.Button.GapFill.Label'
  },
  [ButtonType.GAP_FILL_DND_INPUT]: {
    id: 'Slate.Toolbar.Button.GapFill.Label'
  },
  [ButtonType.FONT_SIZE_SMALLER]: {
    id: 'Slate.Toolbar.Button.FontSizeSmaller'
  },
  [ButtonType.FONT_SIZE_SMALLEST]: {
    id: 'Slate.Toolbar.Button.FontSizeSmallest'
  },
  [ButtonType.FONT_SIZE_SMALL]: {
    id: 'Slate.Toolbar.Button.FontSizeSmall'
  },
  [ButtonType.FONT_SIZE_DEFAULT]: {
    id: 'Slate.Toolbar.Button.FontSizeDefault'
  },
  [ButtonType.FONT_SIZE_LARGE]: {
    id: 'Slate.Toolbar.Button.FontSizeLarge'
  },
  [ButtonType.FONT_SIZE_LARGEST]: {
    id: 'Slate.Toolbar.Button.FontSizeLargest'
  },
  [ButtonType.TOGGLE_EXAMPLE]: {
    id: 'Slate.Toolbar.Button.ToggleExample.Label'
  }
});
