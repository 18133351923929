import {type Editor, type SlateError} from '@englex/slate';

import {buttonTitle} from '../i18n';
import NestedToolbar from '../NestedToolbar';
import {ButtonType, type SchemaInlinePlugin, type SchemaInlineRules} from '../../interface';
import {SlateInline, SlateObject} from '../../../../interface';
import Icon from '../Icon/index';
import RenderIcon from '../../../../plugins/renderers/Icon';
import Char from '../Char/index';
// TODO: perhaps default sets should be the options of this plugin
import defaultCharSet from './charset/default';
import defaultIconSet from '../../../../plugins/renderers/Icon/iconset/default';
import {type ToolbarButtonOptions} from '../../ToolbarButton';

interface IconSet {
  [key: string]: string;
}
type CharSet = string[];

interface Options extends ToolbarButtonOptions {
  iconset?: IconSet;
  charset?: CharSet;
}

class CharSelector extends NestedToolbar implements SchemaInlinePlugin {
  public icon = 'virc-omega';
  public title = buttonTitle.CharSelector;
  public type = ButtonType.CHAR_SELECTOR;
  public maxItemsInRow = 5;
  public handleClick = this.toggle;

  public inlineRules = (): SchemaInlineRules => ({
    type: SlateInline.ICON,
    rules: {
      data: {
        icon: (i: string) => !!i && Object.keys(defaultIconSet).indexOf(i) >= 0,
        additionalClass: (c?: string) => !c || typeof c === 'string'
      },
      isVoid: true
    },
    normalizer: {
      predicate: ({child}: SlateError) =>
        !!child && child.object === SlateObject.INLINE && child.type === this.type,
      reasons: {
        node_data_invalid: (change: Editor, {node}: SlateError) => {
          change.removeNodeByKey(node.key);
          return true;
        }
      }
    }
  });

  constructor(options?: Options) {
    super(options);
    let cs;
    let is;
    if (options) {
      [cs, is] = [options.charset, options.iconset];
    }

    const charset = cs || defaultCharSet;
    const iconset = is || defaultIconSet;

    const icons = Object.keys(iconset).map(
      icon => new Icon({icon, code: iconset[icon], toolbar: this})
    );
    const chars = charset.map((char: string) => new Char({icon: char, toolbar: this}));

    this.plugins = [...icons, ...chars];
    this.plugins.push(new RenderIcon());
  }
}

export default CharSelector;
