import {type Editor, type Mark, type RangeType, type Value} from '@englex/slate';
import {type Set} from 'immutable';

import {letterMatcher, wordFilter, wordSplitter} from 'config/static';

export const replaceMarks = (change: Editor, buffer: Set<Mark>) => {
  const {
    value,
    value: {document}
  } = change;
  const ranges = valueRanges(value);

  if (!ranges) return;

  const [range] = ranges;

  if (range.isExpanded) {
    change.withoutNormalizing(() => {
      value.marks.forEach((m: Mark) => change.removeMark(m));

      buffer.forEach((m: Mark) => {
        const ranges = valueRanges(change.value);

        if (!ranges) return;

        const [valueRange] = ranges;

        change.addMarkAtRange(valueRange, m);
      });
    });
  } else {
    const range = getExpandedRange(change);

    if (!range) return;

    const {word, expandedRange} = range;

    if (wordFilter.test(word) && letterMatcher.test(word)) {
      const marks = document.getMarksAtRange(expandedRange);
      change.withoutNormalizing(() => {
        marks.forEach((m: Mark) => change.removeMarkAtRange(expandedRange, m));

        buffer.forEach((m: Mark) => {
          const range = getExpandedRange(change);

          if (!range) return;

          const {expandedRange} = range;

          change.addMarkAtRange(expandedRange, m);
        });
      });
    }
  }

  return change.withoutSaving(() => {
    change.setData(change.value.data.delete('formatBuffer'));
  });
};

export const valueRanges = (value: Value): [RangeType, RangeType] | undefined => {
  const range = value.selection?.toRange();

  if (!range) return;

  const collapsedRange = range.updatePoints(() => range.start);
  return [range, collapsedRange];
};

const getExpandedRange = (change: Editor) => {
  const {
    value,
    value: {document}
  } = change;

  const ranges = valueRanges(value);

  if (!ranges) return;

  const [, collapsedRange] = ranges;

  const text = document.getText();
  const globalOffset = document.getOffsetAtRange(collapsedRange);
  const [before, after] = [text.slice(0, globalOffset), text.slice(globalOffset)];
  // search up to first non letter character. '!' addresses edge cases when formatted
  // word is at start or end of block/document. in this case search returns -1 and not the
  // number of letters in word before/after collapsed selection. proper number will be
  // returned with those '!' at respective slices' edges.
  const toRight = `${after}!`.search(wordSplitter);
  const toLeft = `!${before}`.split('').reverse().join('').search(wordSplitter);

  const word = text.slice(globalOffset - toLeft, globalOffset + toRight);

  const isEndOfBlock =
    toLeft === 0 && value.anchorText?.text?.length === collapsedRange.anchor.offset;
  const isStartOfBlock = toRight === 0 && !value.anchorText?.text?.includes(word);
  const index = collapsedRange.anchor.path.size - 1;

  const expandedRange = isEndOfBlock
    ? collapsedRange
        .moveFocusTo(collapsedRange.anchor.path.update(index, value => value + 1))
        .moveFocusForward(toRight)
    : isStartOfBlock
      ? collapsedRange
          .moveAnchorTo(collapsedRange.anchor.path.update(index, value => value - 1))
          .moveFocusTo(collapsedRange.anchor.path.update(index, value => value - 1))
          .moveFocusForward(toLeft)
      : collapsedRange.moveEndForward(toRight).moveStartBackward(toLeft);

  return {word, expandedRange};
};
