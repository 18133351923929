import {type Editor} from '@englex/slate';

import ToolbarButton, {type ToolbarButtonOptions} from '../../ToolbarButton';
import {ButtonType} from '../../interface';
import {insertIcon} from './changes';

export default class Icon extends ToolbarButton {
  public icon: string;
  public code: string;
  public title = undefined;
  public type = ButtonType.ICON;

  public toggleChange = (ch: Editor) => {
    ch.command(insertIcon, this.icon);
  };

  constructor({icon, code, toolbar}: ToolbarButtonOptions & {icon: string; code: string}) {
    super({toolbar});
    this.icon = icon;
    this.code = code;
  }
}
