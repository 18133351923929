import type * as Html from '@englex/slate-html-serializer';

import ToggleMark from './ToggleMark';
import {buttonTitle} from './i18n';
import {ButtonType} from '../interface';
import {SlateMark, SlateObject} from '../../../interface';
import RenderBold from '../../../plugins/renderers/Bold';

class Bold extends ToggleMark {
  public type = ButtonType.BOLD;
  public declare label?: undefined;
  public mark = SlateMark.BOLD;
  public icon = 'bold';
  public title = buttonTitle.Bold;
  public shortcut = 'mod+b';

  public plugins = [new RenderBold()];

  public fromHtml: Html.DeserializeFn = (el: HTMLElement, next) => {
    let fontWeight: string | null = el.style && el.style.fontWeight; // from google-doc inline style

    if (!fontWeight && el.tagName.toLowerCase() === 'b') {
      // macOS or msWord
      fontWeight = 'bold';
    }

    if (fontWeight) {
      if (fontWeight === 'bold' || Number(fontWeight) > 400) {
        return {
          object: SlateObject.MARK,
          type: this.mark,
          nodes: next(el.childNodes)
        };
      }
    }

    return;
  };
}

export default Bold;
